import { Injectable, NgZone, inject } from '@angular/core';
import { AuthService } from '@app/core/services/client/auth.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { AuthActions } from './auth.actions';
import { Subscription, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { SIGNUP_TOKEN, TOKEN } from '@app/core/constants/app.constant';
import { AccountService } from '@app/core/services/client/account.service';
import { IAuthStateModel, IAccountStateModel } from './auth.state.models';
import { PortfolioService } from '@app/core/services/client/portfolio.service';
import { ProductService } from '@app/core/services/client/product.service';



@State<IAuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    id: null,
    isAccountSetup: null,
    loading: null,
    success: false,
    error: null,
    account_id: null,
    activeAccount: null,
    allAccounts: [],
    enums: null,
    ngnPortfolioBalance: null,
    usdPortfolioBalance:null,
    walletBalance:null
  },
})
@Injectable()
export class AuthState {
  subs: Subscription[] = [];
  authService = inject(AuthService);
  accountService = inject(AccountService);
  store = inject(Store);
  router = inject(Router);
  productService = inject(ProductService)
  porfolioService = inject(PortfolioService)
  private ngZone = inject(NgZone);


  // Helper method to fetch and format accounts
  private async fetchAndFormatAccounts(userId: string) {
    try {
      const accountList = await firstValueFrom(
        this.accountService.getAccountsAttachedToUsers({ user_identifier: userId })
      );

      let accounts: IAccountStateModel[] = [];
      for (let i = 0; i < accountList.data.length; i++) {
        const res = await firstValueFrom(
          this.accountService.getAccountDetails(accountList.data[i].id)
        );
        accounts.push(res.data);
      }


      if (accounts.length === 0) {
        this.ngZone.run(() => this.router.navigateByUrl('/create-account/type'));
        return null;
      }
      return accounts;
    } catch (error) {
      console.error('Error fetching accounts:', error);
      throw error;
    }
  }

  @Action(AuthActions.GetActiveAccountDetails)
  async getAndupdateActiveAccountDetails(ctx: StateContext<IAuthStateModel>, action: AuthActions.GetActiveAccountDetails) {
    try {
      const account = await firstValueFrom(
        this.accountService.getAccountDetails(
          action?.accountId || ctx.getState().activeAccount?.id || ''
        )
      );
      ctx.setState({
        ...ctx.getState(),
        activeAccount: account.data,
        allAccounts: ctx.getState().allAccounts.map(acc => acc.id === account.data.id ? account.data : acc)
      });
    } catch (error) {
      console.error('Error fetching account:', error);
      throw error;
    }
  }

  @Action(AuthActions.EnumsList)
  private async getAllEnums(ctx: StateContext<IAuthStateModel>) {
    try {
      const enums = await firstValueFrom(
        this.accountService.getAllEnums()
      );
      ctx.setState({
        ...ctx.getState(),
        enums: enums.data,
      });

    } catch (error) {
      console.error('Error fetching accounts:', error);
      throw error;
    }
  }

  @Action(AuthActions.Login)
  async login(ctx: StateContext<IAuthStateModel>, action: AuthActions.Login) {
    ctx.patchState({ loading: true });

    try {
      const response = await firstValueFrom(this.authService.login(action.payload));
      sessionStorage.setItem(TOKEN, response.token);

      // Cache profile_id
      localStorage.setItem('profile_id', response.id);

      ctx.patchState({
        token: response.token,
        id: response.id,
        isAccountSetup: response.is_account_setup,
        success: true,
        error: null,
      });

      const formattedAccounts = await this.fetchAndFormatAccounts(response.id);
      // Same user is logged into this device
      // Second user is logged into this device
      // No user has ever logged into this device
      if (formattedAccounts?.length) {
        const cachedAccountId = localStorage.getItem('active_account_id');

        const findActiveAccount = cachedAccountId ? formattedAccounts.find(account => account.id === cachedAccountId) : undefined;

        const activeAccountIndex = findActiveAccount ? formattedAccounts.findIndex(acc => acc.id === findActiveAccount.id) : 0;

        const activeAccount = formattedAccounts[activeAccountIndex]
        localStorage.setItem('active_account_id', activeAccount.id);
        const ngnPortfolioBalancePayload = {account_id:activeAccount.id, currency:"NGN"};
        const usdPortfolioBalancePayload = {account_id:activeAccount.id, currency:"USD"};
        const ngnPortFolioResponse = await firstValueFrom(this.porfolioService.getPortfolioBalance(ngnPortfolioBalancePayload));
        const usdPortFolioResponse = await firstValueFrom(this.porfolioService.getPortfolioBalance(usdPortfolioBalancePayload));
        const walletResponse = await firstValueFrom(this.productService.getWalletBalance(activeAccount.id))
        const walletBalance = walletResponse.data[1].wallet_balance;
        ctx.dispatch(
          new AuthActions.LoadUserAccountsSuccess(activeAccount, formattedAccounts)
        );
        ctx.setState({
          ...ctx.getState(),
          activeAccount: activeAccount,
          allAccounts: formattedAccounts,
          ngnPortfolioBalance: ngnPortFolioResponse.data.balance / 100 || 0,
          usdPortfolioBalance: usdPortFolioResponse.data.balance / 100 || 0,
          walletBalance: walletBalance / 100 || 0,
        });
        this.ngZone.run(() => this.router.navigateByUrl('dashboard'));
      }
    } catch (error: any) {
      if (error.status === 409) {
        localStorage.setItem(SIGNUP_TOKEN, error.error.token);
        this.ngZone.run(() => this.router.navigateByUrl('/create-account/verification'));
      } else {
        ctx.patchState({ loading: false, error: error.message || 'Login failed' });
        // ctx.dispatch(new AuthActions.LoadUserAccountsFailure(error));
      }
    } finally {
      ctx.patchState({ loading: false });
    }
  }


  @Action(AuthActions.LoadUserAccounts)
  async loadUserAccounts(ctx: StateContext<IAuthStateModel>) {
    const state = ctx.getState();

    // Retrieve cached profile_id and active_account_id from localStorage
    const profile_id = localStorage.getItem('profile_id');
    const active_account_id = localStorage.getItem('active_account_id');

    if (!profile_id) {
      throw new Error('User profile_id not found in storage');
    }

    try {
      const formattedAccounts = await this.fetchAndFormatAccounts(profile_id);

      if (!formattedAccounts) {
        throw new Error('No accounts found for user');
      }

      const activeAccount = formattedAccounts.find(account => account.id === active_account_id) || formattedAccounts[0];
       const ngnPortfolioBalancePayload = {account_id:activeAccount.id, currency:"NGN"};
        const usdPortfolioBalancePayload = {account_id:activeAccount.id, currency:"USD"};
        const ngnPortFolioResponse = await firstValueFrom(this.porfolioService.getPortfolioBalance(ngnPortfolioBalancePayload));
        const usdPortFolioResponse = await firstValueFrom(this.porfolioService.getPortfolioBalance(usdPortfolioBalancePayload));
        const walletResponse = await firstValueFrom(this.productService.getWalletBalance(activeAccount.id))
        const walletBalance = walletResponse.data[1].wallet_balance;

      // Update state with all accounts and active account
      ctx.patchState({
        allAccounts: formattedAccounts,
        activeAccount: activeAccount,
        account_id: activeAccount?.id || null,
        id: profile_id,
        walletBalance: walletBalance / 100 || 0,
        ngnPortfolioBalance: ngnPortFolioResponse.data.balance / 100 || 0,
        usdPortfolioBalance: usdPortFolioResponse.data.balance / 100 || 0,
      });
      ctx.setState({
        ...state,
        allAccounts: formattedAccounts,
        activeAccount: activeAccount,
        walletBalance:walletBalance /100 || 0,
        ngnPortfolioBalance: ngnPortFolioResponse.data.balance || 0,
        usdPortfolioBalance: usdPortFolioResponse.data.balance || 0,
        id: profile_id,
      })
    } catch (error) {
      console.error('Error loading user accounts:', error);
      // ctx.patchState({ allAccounts: [], activeAccount: null });
    }
  }

  @Action(AuthActions.SwitchAccount)
  async accountSwitch(ctx: StateContext<IAuthStateModel>, action: AuthActions.SwitchAccount) {
    const state = ctx.getState();

    try {
      const formattedAccounts = ctx.getState().allAccounts;

      if (!formattedAccounts) {
        throw new Error('No accounts found for user');
      }


      const activeAccount = formattedAccounts.find(account => account.id === action.accountId);
      localStorage.setItem('active_account_id', activeAccount?.id || formattedAccounts[0].id);
        const ngnPortfolioBalancePayload = {account_id:activeAccount!.id, currency:"NGN"};
        const usdPortfolioBalancePayload = {account_id:activeAccount!.id, currency:"USD"};
        const ngnPortFolioResponse = await firstValueFrom(this.porfolioService.getPortfolioBalance(ngnPortfolioBalancePayload));
        const usdPortFolioResponse = await firstValueFrom(this.porfolioService.getPortfolioBalance(usdPortfolioBalancePayload));
        const walletResponse = await firstValueFrom(this.productService.getWalletBalance(activeAccount!.id))
        const walletBalance = walletResponse.data[1].wallet_balance;

      ctx.setState({
        ...state,
        allAccounts: formattedAccounts,
        activeAccount: activeAccount || formattedAccounts[0],
        walletBalance:walletBalance /100 || 0,
        ngnPortfolioBalance: ngnPortFolioResponse.data.balance || 0,
        usdPortfolioBalance: usdPortFolioResponse.data.balance || 0,
      })
    } catch (error) {
      console.error('Error loading user accounts:', error);
      // ctx.patchState({ allAccounts: [], activeAccount: null });
    }
  }


  @Action(AuthActions.SetAccountID)
  createAccount(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetAccountID) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      account_id: action.account_id,
    });
  }

@Action(AuthActions.SetPortFolioBalance)
async createPofolioBalance(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetPortFolioBalance) {
  const state = ctx.getState();
  try {
    const portfolioResponse = await firstValueFrom(
      this.porfolioService.getPortfolioBalance({ account_id: action.account_id, currency: action.currency })
    );
    ctx.setState({
      ...state,
      ...(action.currency === 'NGN' ? { ngnPortfolioBalance: portfolioResponse.data.balance } : 0),
      ...(action.currency === 'USD' ? { usdPortfolioBalance: portfolioResponse.data.balance } : 0)
    });
  } catch (error) {
    console.error('Error fetching portfolio balance:', error);
  }
}
@Action(AuthActions.SetWalletBalance)
async createWalletBalance(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetWalletBalance) {
  const state = ctx.getState();
   const walletResponse = await firstValueFrom(this.productService.getWalletBalance(action.account_id))   
   const walletBalance = walletResponse.data[1].wallet_balance;
    ctx.setState({
      ...state,
      walletBalance:walletBalance /100 || 0
    });
}

  @Action(AuthActions.LoadUserAccountsSuccess)
  loadUserAccountsSuccess(ctx: StateContext<IAuthStateModel>, action: AuthActions.LoadUserAccountsSuccess) {
    ctx.patchState({
      activeAccount: action.activeAccount,
      allAccounts: action.allAccounts,
    });
  }

  @Action(AuthActions.SetPortFolioBalance)
  async createPorfolioBalance(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetPortFolioBalance) {
    const state = ctx.getState();
    try {
      const portfolioResponse = await firstValueFrom(
        this.porfolioService.getPortfolioBalance({ account_id: action.account_id, currency: action.currency })
      );
      ctx.setState({
        ...state,
        ...(action.currency === 'NGN' ? { ngnPortfolioBalance: portfolioResponse.data.balance / 100 } : 0),
        ...(action.currency === 'USD' ? { usdPortfolioBalance: portfolioResponse.data.balance / 100 } : 0)
      });
    } catch (error) {
      console.error('Error fetching portfolio balance:', error);
    }
  }

  @Action(AuthActions.SetLoadingStatus)
  setLoading(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetLoadingStatus) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loading: action.payload,
    });
  }

  @Action(AuthActions.Signup)
  signup(ctx: StateContext<IAuthStateModel>, action: AuthActions.Signup) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      id: action.payload.userId,
      token: action.payload.token,
      // isAccountSetup: true,
      loading: false,
      success: true,
      error: null,
    });
  }
}
