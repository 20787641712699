import { finalize, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LoadingService } from '../loading.service';
import { Store } from '@ngxs/store';
import { environment } from '@app/core/environments/environment';
import { IBillsProductResponse, IBondsProductResponse, ICreateOrderResponse, IFixedDepositProductResponse, IMutualFundsPerformanceResponse, IProductRateResponse, IWalletBalanceResponse } from '@app/core/models/client/products/products.model';
import { Pagination } from '@app/core/models/client/account/pagination.models';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  http = inject(HttpClient);
  loadingService = inject(LoadingService);
  store = inject(Store)

  BASE_URL = environment.CLIENT_BASE_URL + '/v1';

  constructor() { }

  getFixedDepositProducts(): Observable<IFixedDepositProductResponse> {
    const uniqueStateUrl = 'get-fixed-deposit-product';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<IFixedDepositProductResponse>(
        `${this.BASE_URL}/product/get-all-client-products?category=LCM`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getBondsProducts(): Observable<IBondsProductResponse> {
    const uniqueStateUrl = 'get-bond-product';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<IBondsProductResponse>(
        `${this.BASE_URL}/product/get-bond-products`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getCommercialPaperProducts(): Observable<IBillsProductResponse> {
    const uniqueStateUrl = 'get-commercial-paper';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<IBillsProductResponse>(
        `${this.BASE_URL}/product/get-commercial-paper`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getTBillProducts(): Observable<IBillsProductResponse> {
    const uniqueStateUrl = 'get-tbill-product';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<IBillsProductResponse>(
        `${this.BASE_URL}/product/get-tbill-products`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getWalletBalance(accountId: string): Observable<IWalletBalanceResponse> {
    const uniqueStateUrl = 'get-wallet-balance';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<IWalletBalanceResponse>(
        `${this.BASE_URL}/account/get-wallet-balance?account_id=${accountId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getProductRate(product_id: string): Observable<IProductRateResponse> {
    const uniqueStateUrl = 'get-product-rate';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<IProductRateResponse>(`${this.BASE_URL}/rate/get-product-rate`, {
        params: { product_id }
      })
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getMutualFundsPerformanceList(data: Pagination = new Pagination(), account_number: string, queryParams: string = ''): Observable<IMutualFundsPerformanceResponse> {
    const uniqueStateUrl = 'get-portfolio-performance';
    this.loadingService.setLoading(uniqueStateUrl, true);

    let url = `${this.BASE_URL}/product/get-mutual-fund-performance-by-account?take=${data.size}&skip=${data.skip}&account_number=${account_number}`;

    // Append additional query parameters if they exist
    if (queryParams) {
      url += `&${queryParams}`;
    }

    return this.http
      .get<IMutualFundsPerformanceResponse>(url)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getMutualFund(queryParams: any): Observable<any> {
    const uniqueStateUrl = 'get-mutual-fund';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<any>(
        `${this.BASE_URL}/product/get-mutual-fund?skip=${queryParams?.skip}&sort=${queryParams?.sort}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }


  getAssets(accountId: string, type: string): Observable<any> {
    const uniqueStateUrl = 'get-assets';
    this.loadingService.setLoading(uniqueStateUrl, true);

    // Determine the endpoint based on the type
    let endpoint: string;
    if (type === 'LCM') {
      endpoint = `${this.BASE_URL}/portfolio/get-lcm-portfolio-list?account_id=${accountId}`;
    } else if (type === 'PMS') {
      endpoint = `${this.BASE_URL}/portfolio/get-pms-portfolio-list?account_id=${accountId}`;
    } else {
      throw new Error(`Unsupported type: ${type}`);
    }

    // Make the HTTP request
    return this.http.get<any>(endpoint).pipe(
      finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
    );
  }


  createOrder(accountId: any, orderData: any, payment_source: string): Observable<ICreateOrderResponse> {
    const uniqueStateUrl = 'create-order';
    this.loadingService.setLoading(uniqueStateUrl, true);

    let url: string;

    // Check payment_source and assign appropriate endpoint
    if (payment_source === 'wallet') {
      url = `${this.BASE_URL}/order/create-order`;
    } else {
      url = `${this.BASE_URL}/order/create-cash-order`;
    }

    // Construct payload with additional data
    const payload = {
      ...orderData,
      account_id: accountId,
      currency: 'NGN',
    };

    return this.http.post<ICreateOrderResponse>(url, payload).pipe(
      finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
    );
  }
  createMutualFundOrder(accountId: any, orderData: any): Observable<any> {
    const uniqueStateUrl = 'create-mutual-fund-order';
    this.loadingService.setLoading(uniqueStateUrl, true);
    const payload = {
      ...orderData,
      account_id: accountId,
    };
    return this.http.post<any>(`${this.BASE_URL}/order/create-mutual-fund-order`, payload).pipe(
      finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
    );
  }

  setPMSAgreement(accountId: any): Observable<any> {
    const uniqueStateUrl = 'pms-agreement';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http.post<any>(
      `${this.BASE_URL}/account/accept-pms-investment-agreement`,
      { account_id: accountId }).pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  setMFDAgreement(accountId: any): Observable<any> {
    const uniqueStateUrl = 'mfd-agreement';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http.post<any>(
      `${this.BASE_URL}/account/accept-mutual-fund-agreement`,
      { account_id: accountId }).pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  setLCMAgreement(accountId: any): Observable<any> {
    const uniqueStateUrl = 'lcm-agreement';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http.post<any>(
      `${this.BASE_URL}/account/accept-lcm-investment-agreement`,
      { account_id: accountId }).pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}  