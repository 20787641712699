import { Selector } from '@ngxs/store';
import { IAccountStateModel, IAuthStateModel } from './auth.state.models';
import { AuthState } from './auth.state';
import { IEnums } from '@app/core/models/utils/enums.model';



export class AuthSelectors {
  @Selector([AuthState])
  static getAuthToken(state: IAuthStateModel): string | null {
    return state.token;
  }
  @Selector([AuthState])
  static getProfileID(state: IAuthStateModel): string | null {
    return state.id || localStorage.getItem('profile_id');
  }
  @Selector([AuthState])
  static getNGNBalance(state: IAuthStateModel): number | null {
    return state.ngnPortfolioBalance; 
  }
  @Selector([AuthState])
  static getUSDBalance(state: IAuthStateModel): number | null {
    return state.usdPortfolioBalance; 
  }
   @Selector([AuthState])
  static getWalletBalance(state: IAuthStateModel): number | null {
    return state.walletBalance; 
  }
  @Selector([AuthState])
  static getaccountID(state: IAuthStateModel): string | null {
    return state.account_id;
  }
  @Selector([AuthState])
  static getAllEnumData(state: IAuthStateModel): IEnums | null {
    return state.enums;
  }
  @Selector([AuthState])
  static getActiveAccountID(state: IAuthStateModel): string | null {
    return state.activeAccount?.id || null;
  }
  @Selector([AuthState])
  static getActiveAccount(state: IAuthStateModel): IAccountStateModel | null {
    return state.activeAccount;
  }

  @Selector([AuthState])
  static getLoadingStatus(state: IAuthStateModel): boolean | null {
    return state.loading;
  }

  @Selector([AuthState])
  static isAccountSetup(state: IAuthStateModel): boolean | null {
    return state.isAccountSetup;
  }

  @Selector([AuthState])
  static getAllAccounts(state: IAuthStateModel): IAccountStateModel[] {
    return state.allAccounts;
  }

  @Selector([AuthState])
  static isAccountsLoaded(state: IAuthStateModel) {
    return !!state.activeAccount && !!state.allAccounts?.length;
  }
  @Selector()
  static getError(state: { auth: IAuthStateModel }): any {
    return state.auth.error;
  }

}
