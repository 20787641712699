import { finalize, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LoadingService } from '../loading.service';
import { Store } from '@ngxs/store';
import { environment } from '@app/core/environments/environment';
import { IBillsProductResponse, IBondsProductResponse, IFixedDepositProductResponse, IPortfolioCashResponse, PortFioBalancePayload } from '@app/core/models/client/products/products.model';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  http = inject(HttpClient);
  loadingService = inject(LoadingService);
  store = inject(Store)

  BASE_URL = environment.CLIENT_BASE_URL + '/v1';

  constructor() { }

  getPortfolioCash(accountId: string, currency: string): Observable<IPortfolioCashResponse> {
    const uniqueStateUrl = 'get-portfolio-cash';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<IPortfolioCashResponse>(
        `${this.BASE_URL}/portfolio/get-portfolio-cash?account_id=${accountId}&currency=${currency}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );

  }
  getPortfolioBalance(payload: PortFioBalancePayload): Observable<any> {
    const uniqueStateUrl = 'get-portfolio-balance';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<any>(
        `${this.BASE_URL}/portfolio/get-portfolio-balance`, payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  getPortfolioStat(accountId: string, currency: string): Observable<any> {
    const uniqueStateUrl = 'get-portfolio-stat';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<any>(
        `${this.BASE_URL}/portfolio/get-portfolio-chart?account_id=${accountId}&currency=${currency}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getPortfolioList(accountId: string, currency: string): Observable<any> {
    const uniqueStateUrl = 'get-portfolio-list';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<any>(
        `${this.BASE_URL}/portfolio/get-portfolio-list?account_id=${accountId}&currency=${currency}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

}
